import { Button, Drawer, Form, Input, Select, notification, Table, Spin, Tag, Modal } from 'antd'
import React, { useState, useEffect } from 'react'

import { codeclubs } from '../../../Files/centers'
import { auth, db } from '../../../firebase'
import { createUserWithEmailAndPassword } from 'firebase/auth'
import { collection, addDoc, getDocs, updateDoc, doc, setDoc } from 'firebase/firestore'
import loginsData from '../../../Files/logins'

const Volunteers = ({ user }) => {
    const [addVolunteerOpen, setAddVolunteerOpen] = useState(false)
    const [form] = Form.useForm();
    const [api, contextHolder] = notification.useNotification();
    const [selectedRole, setSelectedRole] = useState('admin');
    const [isLoading, setIsLoading] = useState(false);
    const [users, setUsers] = useState([]);
    const [loadingUsers, setLoadingUsers] = useState(false);
    const [confirmModalVisible, setConfirmModalVisible] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);

    const centerOptions = codeclubs.map(center => ({
        label: center.name,
        value: center.id
    }));

    const fetchUsers = async () => {
        setLoadingUsers(true);
        try {
            const querySnapshot = await getDocs(collection(db, 'users'));
            const usersData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setUsers(usersData);
        } catch (error) {
            console.error('Error fetching users:', error);
            api.error({
                message: 'Error',
                description: error.message,
            });
        }
        setLoadingUsers(false);
    };

    useEffect(() => {
        fetchUsers();
    }, []);

    const onFinish = async (values) => {
        const { email, password, role, centers } = values;
        setIsLoading(true);
        try {
            const userCredential = await createUserWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;

            await setDoc(doc(db, 'users', user.uid), {
                uid: user.uid,
                email: email,
                role: role,
                centers: centers || [],
                active: 'pending',
            });

            api.success({
                message: 'User Created',
                description: `The user ${email} has been successfully created.`,
            });

            setAddVolunteerOpen(false);
            form.resetFields();

            // Refresh the users list
            fetchUsers();

        } catch (error) {
            console.error('Error creating user:', error);
            api.error({
                message: 'Error',
                description: error.message,
            });
        }
        setIsLoading(false);
    };

    const handleActivate = async () => {
        if (selectedUser) {
            try {
                await updateDoc(doc(db, 'users', selectedUser.id), {
                    active: 'approved'
                });
                api.success({
                    message: 'User Activated',
                    description: `The user ${selectedUser.email} has been successfully activated.`,
                });
                fetchUsers();
            } catch (error) {
                console.error('Error activating user:', error);
                api.error({
                    message: 'Error',
                    description: error.message,
                });
            }
        }
        setConfirmModalVisible(false);
        setSelectedUser(null);
    };

    const columns = [
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            width: 200,
        },
        {
            title: 'Role',
            dataIndex: 'role',
            key: 'role',
            filters: [
                { text: 'Super Admin', value: 'super-admin' },
                { text: 'Admin', value: 'admin' },
                { text: 'Coordinator', value: 'coordinator' },
            ],
            onFilter: (value, record) => record.role === value,
            render: (role) => {
                let color = '';
                if (role === 'super-admin') {
                    color = 'volcano';
                } else if (role === 'admin') {
                    color = 'geekblue';
                } else if (role === 'coordinator') {
                    color = 'green';
                }
                return <Tag color={color} key={role}>{role.toUpperCase()}</Tag>;
            },
            width: 150,
        },
        {
            title: 'Clubs',
            dataIndex: 'centers',
            key: 'centers',
            filters: codeclubs.map(center => ({
                text: center.name,
                value: center.id,
            })),
            onFilter: (value, record) => record.centers.includes(value),
            render: (centers) => (
                centers ? centers.map(centerId => {
                    const center = codeclubs.find(c => c.id === centerId);
                    return center ? <Tag key={centerId}>{center.name}</Tag> : <Tag key={centerId}>{centerId}</Tag>;
                }) : ''
            ),
            width: 300,
        },
        {
            title: 'Active Status',
            dataIndex: 'active',
            key: 'active',
            render: (text, record) => (
                text === 'approved' ? (
                    <Tag color="green">Active</Tag>
                ) : (
                    <div>
                        <Tag color="red">Penidng</Tag>
                        {user?.role === 'super-admin' &&
                            <Button Button type="primary" onClick={() => {
                                setSelectedUser(record);
                                setConfirmModalVisible(true);
                            }}>
                                Activate
                            </Button>
                        }
                    </div >
                )
            ),
            sorter: (a, b) => b.active === 'pending' ? -1 : 1,
            defaultSortOrder: 'descend',
            width: 100,
        },
    ];

    const createAllVolunteers = async () => {
        setIsLoading(true);
        try {
            for (const login of loginsData) {
                const { email, password, role, centerId } = login;
                try {
                    console.log(`Creating ${email}...`);
                    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
                    const user = userCredential.user;

                    await setDoc(doc(db, 'users', user.uid), {
                        uid: user.uid,
                        email: email,
                        role: role,
                        centers: centerId || [],
                        active: 'approved',
                    });

                    console.log(`Successfully created ${email}`);
                } catch (error) {
                    console.error(`Error creating ${email}:`, error);
                    console.log(`Skipped ${email}`);
                }
            }

            api.success({
                message: 'All Users Created',
                description: 'All volunteers have been successfully created.',
            });
            fetchUsers();

        } catch (error) {
            console.error('Error creating users:', error);
            api.error({
                message: 'Error',
                description: error.message,
            });
        }
        setIsLoading(false);
    };



    return (
        <div className='volunteers'>
            {contextHolder}
            <div className='header'>
                <Button type='primary' onClick={() => setAddVolunteerOpen(true)} disabled={user?.role !== 'super-admin'}>
                    Add Volunteer
                </Button>
                {/* <Button type='primary' onClick={createAllVolunteers} loading={isLoading}>
                    Add All
                </Button> */}
            </div>

            <Spin spinning={loadingUsers}>
                <Table dataSource={users} columns={columns} rowKey='id' />
            </Spin>

            <Drawer
                title="Add User"
                width={400}
                onClose={() => setAddVolunteerOpen(false)}
                open={addVolunteerOpen}
                bodyStyle={{ paddingBottom: 80 }}
            >
                <Form
                    layout="vertical"
                    onFinish={onFinish}
                    form={form}
                    initialValues={{ role: 'admin' }}
                >
                    <Form.Item
                        name="email"
                        label="Email"
                        rules={[
                            { required: true, message: 'Please enter the email' },
                            { type: 'email', message: 'Please enter a valid email' }
                        ]}
                    >
                        <Input placeholder="Enter email" />
                    </Form.Item>

                    <Form.Item
                        name="password"
                        label="Password"
                        rules={[
                            { required: true, message: 'Please enter the password' },
                            { min: 6, message: 'Password must be at least 6 characters' }
                        ]}
                    >
                        <Input.Password placeholder="Enter password" />
                    </Form.Item>

                    <Form.Item
                        name="role"
                        label="Role"
                        rules={[{ required: true, message: 'Please select a role' }]}
                    >
                        <Select
                            placeholder="Select role"
                            onChange={(value) => {
                                setSelectedRole(value);
                                if (value === 'super-admin' || value === 'coordinator') {
                                    form.setFieldsValue({ centers: [] });
                                }
                            }}
                        >
                            <Select.Option value="super-admin">Super Admin</Select.Option>
                            <Select.Option value="admin">Admin</Select.Option>
                            <Select.Option value="coordinator">Coordinator</Select.Option>
                        </Select>
                    </Form.Item>

                    <Form.Item
                        name="centers"
                        label="Centers"
                        rules={[
                            {
                                required: selectedRole === 'admin',
                                message: 'Please select at least one center'
                            }
                        ]}
                    >
                        <Select
                            mode="multiple"
                            placeholder="Select centers"
                            options={centerOptions}
                            disabled={selectedRole === 'super-admin' || selectedRole === 'coordinator'}
                        />
                    </Form.Item>

                    <Form.Item>
                        <Button
                            type="primary"
                            htmlType="submit"
                            loading={isLoading}
                        >
                            Add User
                        </Button>
                    </Form.Item>
                </Form>
            </Drawer>

            <Modal
                title="Confirm Activation"
                visible={confirmModalVisible}
                onOk={handleActivate}
                onCancel={() => setConfirmModalVisible(false)}
            >
                <p>Are you sure you want to activate this user?</p>
            </Modal>
        </div>
    )
}

export default Volunteers