import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyDK011ESQ40YLypU2fPzW82gv6oQRW-LAg",
    authDomain: "codeclubsl.firebaseapp.com",
    projectId: "codeclubsl",
    storageBucket: "codeclubsl.appspot.com",
    messagingSenderId: "891462234179",
    appId: "1:891462234179:web:147a78404c70d2da0624f6",
    measurementId: "G-N0NN09PY7F"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);

export { db, auth };