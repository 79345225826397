import { Divider, Drawer, Empty, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { codeclubs } from '../../../Files/centers';

import './styles.scss';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../../../firebase';

const Clubs = () => {
    const [isDrawerVisible, setIsDrawerVisible] = useState(false);
    const [selectedClub, setSelectedClub] = useState(null);
    const [clubStudents, setClubStudents] = useState([]);
    const [loading, setLoading] = useState(false);
    const [studentsCounts, setStudentsCounts] = useState({});

    useEffect(() => {
        handleGetStudents();
    }, [selectedClub]);

    useEffect(() => {
        fetchCountsForAllClubs();
    }, []);

    const fetchCountsForAllClubs = async () => {
        const counts = {};
        for (let club of codeclubs) {
            counts[club.id] = {
                approved: await approvedStudentsCount(club.id),
                pending: await pendingStudentsCount(club.id)
            };
        }
        setStudentsCounts(counts);
    };

    const handleGetStudents = async () => {
        setLoading(true);
        try {
            let newStudents = [];
            const q = query(
                collection(db, 'registrations'),
                where('center', '==', selectedClub.id),
                where('isApproved', '==', 'Approved')
            );

            const studentsSnapshot = await getDocs(q);

            studentsSnapshot?.forEach(doc => {
                const student = doc.data();
                student.id = doc.id;
                newStudents.push(student);
            });

            setClubStudents(newStudents);
        } catch (error) {
            console.log(error);
        }
        setLoading(false);
    };

    const approvedStudentsCount = async (clubId) => {
        try {
            const q = query(
                collection(db, 'registrations'),
                where('center', '==', clubId),
                where('isApproved', '==', 'Approved')
            );

            const approvedSnapshot = await getDocs(q);
            return approvedSnapshot.size;
        } catch (error) {
            console.log("Error: ", error);
            return 'N/A';
        }
    };

    const pendingStudentsCount = async (clubId) => {
        try {
            const q = query(
                collection(db, 'registrations'),
                where('center', '==', clubId),
                where('isApproved', '==', 'Pending')
            );

            const pendingSnapshot = await getDocs(q);
            return pendingSnapshot.size;
        } catch (error) {
            console.log("Error: ", error);
            return 'N/A';
        }
    };


    return (
        <div className='code_clubs'>
            <h1>
                Code Clubs
            </h1>
            <Divider />
            {
                codeclubs.map(club => (
                    <div
                        key={club.id}
                        className='club'
                        onClick={() => {
                            setSelectedClub(club);
                            setIsDrawerVisible(true);
                        }}
                    >
                        <h2>{club.name}</h2>
                        <p><strong>District: </strong>{club.district}</p>
                        <p><strong>Limit: </strong>{club.limit}</p>
                        <p><strong>Approved Students: </strong>{studentsCounts[club.id]?.approved ?? 'Loading...'}</p>
                        <p><strong>Pending Students: </strong>{studentsCounts[club.id]?.pending ?? 'Loading...'}</p>
                    </div>
                ))
            }

            <Drawer
                title={selectedClub?.name}
                placement='right'
                closable={true}
                onClose={() => setIsDrawerVisible(false)}
                open={isDrawerVisible}
                width={720}
                loading={loading}
                rootClassName='drawer_club_students'
            >
                <Spin spinning={loading} tip='Loading students...'>
                    <h3>
                        Maximum Limit: {selectedClub?.limit}
                    </h3>
                    <h3
                        style={{
                            color: clubStudents.length < selectedClub?.limit ? 'green' : 'red'
                        }}
                    >
                        Students: {clubStudents.length}
                        {clubStudents.length < selectedClub?.limit ? ' (Available)' : ' (Full)'}
                    </h3>
                    <h3>
                        Available Slots: {
                            clubStudents.length < selectedClub?.limit
                                ?
                                selectedClub?.limit - clubStudents.length
                                :
                                0
                        }
                    </h3>
                    <Divider />
                    <h2>Students</h2>
                    {
                        clubStudents.map(student => (
                            <div key={student.id} className='student'>
                                <p><strong>Name: </strong>{student.childName}</p>
                                <p><strong>Age: </strong>{student.age}</p>
                                <p><strong>Parent: </strong>{student.parentsName}</p>
                                <p><strong>Parent Contact: </strong>{student.parentsContact}</p>
                                <p><strong>Parent Address: </strong>{student.address}</p>
                                <p><strong>Status: </strong>{student.isApproved ? 'Approved' : 'Pending'}</p>
                            </div>
                        ))
                    }

                    <Empty style={{ display: clubStudents.length ? 'none' : 'block' }} />
                </Spin>
            </Drawer>
        </div>
    );
}

export default Clubs;
