import React, { useState } from 'react'
import './styles.scss'
import { Button, Form, Input, notification } from 'antd'
import { signInWithEmailAndPassword } from 'firebase/auth'
import { collection, query, where, getDocs } from 'firebase/firestore'
import { auth, db } from '../../firebase'

const sanitizeInput = (input) => {
    return input.replace(/[^\w\s@\-.]/gi, '').trim();
};

const Signin = () => {
    const [api, contextHolder] = notification.useNotification();
    const [loading, setLoading] = useState(false);

    const onFinish = async (values) => {
        const email = sanitizeInput(values.email);
        const password = sanitizeInput(values.password);
        setLoading(true);
        try {
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;

            const idToken = await user.getIdToken();

            await new Promise(resolve => setTimeout(resolve, 1000));

            const q = query(collection(db, 'users'), where('uid', '==', user.uid));
            const querySnapshot = await getDocs(q);

            if (!querySnapshot.empty) {
                const userDoc = querySnapshot.docs[0];
                const userData = userDoc.data();

                if (userData.active === 'approved') {
                    api.success({
                        message: 'Login Success',
                        description: 'You have successfully logged in'
                    });
                    localStorage.setItem('login-codeclubs', JSON.stringify({
                        ...userData,
                        idToken
                    }));
                    window.location.href = '/admin';
                } else {
                    api.error({
                        message: 'Login Failed',
                        description: 'Your account is not approved yet.'
                    });
                    await auth.signOut();
                }
            } else {
                console.error('No such document!');
                api.error({
                    message: 'Login Failed',
                    description: 'User document does not exist.'
                });
                await auth.signOut();
            }
        } catch (error) {
            console.error('Error signing in:', error);
            api.error({
                message: 'Login Failed',
                description: 'Please check your email and password'
            });
        }
        setLoading(false);
    };

    return (
        <div className='signin'>
            {contextHolder}
            <div className='container'>
                <div className='signin-header'>
                    <h1>Volunteer Login</h1>
                    <p>Login to your account for more features</p>
                </div>
                <div className='form'>
                    <Form layout='vertical' onFinish={onFinish}>
                        <Form.Item
                            label="Email"
                            name="email"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your email!',
                                },
                                {
                                    type: 'email',
                                    message: 'Please enter a valid email!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="Password"
                            name="password"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your password!',
                                },
                            ]}
                        >
                            <Input.Password />
                        </Form.Item>

                        <Form.Item>
                            <Button
                                type="primary" htmlType="submit"
                                loading={loading}
                            >
                                Sign In
                            </Button>
                        </Form.Item>
                    </Form>
                    <div className='signup-link'>
                        <p>Don't have an account? <a href="/volunteer-signup">Sign up as a volunteer</a></p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Signin;