import React, { useEffect, useRef, useState } from 'react'

import './styles.scss'
import { collection, doc, getDocs, orderBy, query, updateDoc, where } from 'firebase/firestore'
import { db } from '../../../firebase'
import * as XLSX from 'xlsx';
import { codeclubs } from '../../../Files/centers'
import { Button, Input, message, Popconfirm, Space, Table } from 'antd'
import { SearchOutlined } from '@ant-design/icons'

const NewRegistartions = ({ user }) => {
    const [loading, setLoading] = useState(false)
    const [isLoading, setIsLoading] = useState(false);
    const [newRegistrations, setNewRegistrations] = useState([])
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    const [approveLoading, setApproveLoading] = useState("");
    const [messageApi, contextHolder] = message.useMessage();
    const [rejectLoading, setRejectLoading] = useState("");

    const handleApproveAll = async () => {
        setIsLoading(true);
        try {
            const registrationsRef = collection(db, 'registrations');
            const q = query(registrationsRef, where('isApproved', '==', 'Pending'));
            const querySnapshot = await getDocs(q);

            const updatePromises = querySnapshot.docs.map((doc) =>
                updateDoc(doc.ref, { isApproved: "Approved" })
            );

            await Promise.all(updatePromises);  // Wait for all updates to complete
            setIsLoading(false);
            messageApi.open({
                type: 'success',
                content: 'All registrations have been approved!',
            });
            handleGetNewRegistrations();  // Refresh the list
        } catch (error) {
            console.error('Error approving registrations:', error);
            setIsLoading(false);
            messageApi.open({
                type: 'error',
                content: 'An error occurred while approving registrations.',
            });
        }
    };

    const handleExportToExcel = async () => {
        try {
            // Fetch all approved registrations from Firestore
            const registrationsRef = collection(db, 'registrations');
            const querySnapshot = await getDocs(registrationsRef);

            // Prepare data
            let registrations = [];
            querySnapshot.forEach((doc) => {
                const data = doc.data();
                if (data.isApproved === 'Approved') {
                    const club = codeclubs.find(c => c.id === data.center);
                    registrations.push({
                        ...data,
                        clubName: club ? club.name : 'Unknown Club'
                    });
                }
            });

            // Group registrations by center
            const groupedData = registrations.reduce((acc, reg) => {
                const { clubName } = reg;
                if (!acc[clubName]) acc[clubName] = [];
                acc[clubName].push(reg);
                return acc;
            }, {});

            // Prepare data for Excel
            const workbook = XLSX.utils.book_new();
            Object.keys(groupedData).forEach((clubName) => {
                const clubData = groupedData[clubName].map(({ childName, parentsName, gender, age, parentsContact, address, date }) => ({
                    "Child Name": childName,
                    "Parent Name": parentsName,
                    "Gender": gender,
                    "Age": age,
                    "Parent's Contact": parentsContact,
                    "Address": address,
                    "Registration Date": date,
                }));

                const worksheet = XLSX.utils.json_to_sheet(clubData);
                XLSX.utils.book_append_sheet(workbook, worksheet, clubName);
            });

            XLSX.writeFile(workbook, 'New_Registrations.xlsx');
            alert('Exported successfully!');
        } catch (error) {
            console.error('Error exporting to Excel:', error);
            alert('An error occurred while exporting to Excel.');
        }
    };

    const exportApprovedUserContactsToCSV = async () => {
        try {
            const registrationsRef = collection(db, 'registrations');
            const querySnapshot = await getDocs(registrationsRef);

            const approvedUserContacts = querySnapshot.docs
                .map((doc) => {
                    const data = doc.data();
                    const club = codeclubs.find(c => c.id === data.center);

                    if (data.isApproved === 'Approved') {
                        return {
                            "Child Name": data.childName,
                            "Parent Name": data.parentsName,
                            "Parent's Contact": data.parentsContact,
                            "Center": club ? club.name : 'Unknown Center'
                        };
                    }
                    return null;
                })
                .filter(Boolean);
            const worksheet = XLSX.utils.json_to_sheet(approvedUserContacts);

            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Approved User Contacts');

            XLSX.writeFile(workbook, 'Approved_User_Contacts.csv', { bookType: 'csv' });
            alert('Approved user contacts exported to CSV successfully!');
        } catch (error) {
            console.error('Error exporting approved user contacts to CSV:', error);
            alert('An error occurred while exporting to CSV.');
        }
    };



    useEffect(() => {
        handleGetNewRegistrations()
    }, [])

    const handleGetNewRegistrations = async () => {
        setLoading(true);
        try {
            let newRegistrations = [];
            const q = query(
                collection(db, 'registrations'),
                where('isApproved', '==', 'Pending')
            );

            const registrationsSnapshot = await getDocs(q);

            registrationsSnapshot?.forEach(doc => {
                const registration = doc.data();
                registration.id = doc.id;
                newRegistrations.push(registration);
            });

            newRegistrations.sort((a, b) => {
                const dateA = a.date?.split('/').reverse().join('');
                const dateB = b.date?.split('/').reverse().join('');
                return dateB - dateA;
            });

            setNewRegistrations(newRegistrations);
        } catch (error) {
            console.log(error);
        }
        setLoading(false);
    };

    const handleApproveStudent = async (id) => {
        setApproveLoading(id);
        try {
            const studentRef = doc(db, 'registrations', id);

            await updateDoc(studentRef, {
                isApproved: 'Approved'
            });
            messageApi.open({
                type: 'success',
                content: `Student ${newRegistrations.find(s => s.id === id).childName} has been approved`
            })
            setNewRegistrations(newRegistrations.filter(s => s.id !== id));

        } catch (error) {
            console.log(error);
            messageApi.open({
                type: 'error',
                content: `Failed to approve student ${newRegistrations.find(s => s.id === id).childName}`
            })
        }
        setApproveLoading(id);
    };

    const handleRejectStudent = async (id) => {
        setRejectLoading(id);
        try {
            const studentRef = doc(db, 'registrations', id);
            await updateDoc(studentRef, {
                isApproved: 'Rejected'
            });
            messageApi.open({
                type: 'success',
                content: `Student ${newRegistrations.find(s => s.id === id).childName} has been rejected`
            })
            setNewRegistrations(newRegistrations.filter(s => s.id !== id));
        } catch (error) {
            console.log(error);
            messageApi.open({
                type: 'error',

                content: `Failed to reject student ${newRegistrations.find(s => s.id === id).childName}`
            })
        }
        setRejectLoading(id);
    };

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const getNameSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1677ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
    });

    const columns = [
        {
            title: 'Child Name',
            dataIndex: 'childName',
            key: 'childName',
            width: '20%',
            ...getNameSearchProps('childName'),
        },
        {
            title: 'Parent Name',
            dataIndex: 'parentsName',
            key: 'parentsName',
            width: '20%',
            ...getNameSearchProps('parentsName'),
        },
        {
            title: 'Gender',
            dataIndex: 'gender',
            key: 'gender',
            width: '10%',
        },
        {
            title: "Parent's Contact",
            dataIndex: 'parentsContact',
            key: 'parentsContact',
            width: "10%",
        },
        {
            title: "Code Club",
            dataIndex: 'center',
            key: 'center',
            width: '20%',
            render: (center) => codeclubs.find(c => c.id === center)?.name
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            width: '15%',
            sorter: (a, b) => {
                const parseDate = (dateStr) => {
                    const [day, month, year] = dateStr.split('/').map(Number);
                    return new Date(year, month - 1, day);
                };

                const dateA = parseDate(a.date);
                const dateB = parseDate(b.date);

                return dateB - dateA;
            }
        },
        {
            title: 'Action',
            dataIndex: 'id',
            key: 'id',
            render: (id) => (
                <div className='action_Buttons'>
                    <Button
                        type='primary'
                        onClick={() => handleApproveStudent(id)}
                        loading={approveLoading === id}
                        disabled={user?.role !== 'super-admin'}
                    >
                        Approve
                    </Button>

                    <Popconfirm
                        title="Are you sure to delete this student?"
                        onConfirm={() => handleRejectStudent(id)}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button
                            type='primary'
                            danger
                            disabled={user?.role !== 'super-admin'}
                        >
                            Reject
                        </Button>
                    </Popconfirm>
                </div>
            )
        }
    ];

    return (
        <div className='new_registartion'>
            {contextHolder}
            <h1>
                New Registrations
            </h1>

            <div
                style={{
                    display: 'flex',
                    gap: '10px',
                    marginBottom: '20px',
                    marginTop: '10px'
                }}
            >
                {user?.role === 'super-admin' &&
                    <Button
                        onClick={handleApproveAll}
                        disabled={isLoading}>
                        {isLoading ? 'Approving...' : 'Approve All Registrations'}
                    </Button>}

                <Button onClick={handleExportToExcel} disabled={isLoading}>
                    Export Approved Users
                </Button>

                <Button onClick={exportApprovedUserContactsToCSV} disabled={isLoading}>
                    Export Contacts
                </Button>
            </div>

            <Table
                className='table'
                loading={loading}
                columns={columns}
                dataSource={newRegistrations}
            />
        </div>
    )
}

export default NewRegistartions