import React, { useState } from 'react'
import './styles.scss'
import { Button, Form, Input, notification, Select } from 'antd'
import { createUserWithEmailAndPassword } from 'firebase/auth'
import { collection, addDoc, setDoc, doc } from 'firebase/firestore'
import { auth, db } from '../../firebase'
import { codeclubs } from '../../Files/centers'

const { Option } = Select;

const sanitizeInput = (input) => {
    return input.replace(/[^\w\s@\-.]/gi, '').trim();
};

const VolunteerRegister = () => {
    const [api, contextHolder] = notification.useNotification();
    const [loading, setLoading] = useState(false);

    const onFinish = async (values) => {
        const email = sanitizeInput(values.email);
        const password = sanitizeInput(values.password);
        const role = 'admin';
        const centers = values.centers;

        setLoading(true);
        try {
            const userCredential = await createUserWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;

            await setDoc(doc(db, 'users', user.uid), {
                uid: user.uid,
                email: email,
                role: role,
                centers: centers,
                active: 'pending',
            });

            api.success({
                message: 'Registration Successful',
                description: 'Your registration is successful. Please wait for approval.'
            });
            setTimeout(() => {
                window.location.href = '/signin';
            }, 2000);
        } catch (error) {
            console.error('Error registering:', error);
            api.error({
                message: 'Registration Failed',
                description: error.message
            });
        }
        setLoading(false);
    };

    return (
        <div className='register'>
            {contextHolder}
            <div className='container'>
                <div className='register-header'>
                    <h1>Volunteer Registration</h1>
                    <p>Register to become a volunteer</p>
                </div>
                <div className='form'>
                    <Form layout='vertical' onFinish={onFinish}>
                        <Form.Item
                            label="Email"
                            name="email"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your email!',
                                },
                                {
                                    type: 'email',
                                    message: 'Please enter a valid email!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="Password"
                            name="password"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your password!',
                                },
                                {
                                    min: 6,
                                    message: 'Password must be at least 6 characters',
                                },
                            ]}
                        >
                            <Input.Password />
                        </Form.Item>

                        <Form.Item
                            label="Select Centers"
                            name="centers"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select at least one center!',
                                },
                            ]}
                        >
                            <Select
                                mode="multiple"
                                placeholder="Select centers"
                            >
                                {codeclubs.map(center => (
                                    <Option key={center.id} value={center.id}>
                                        {center.name}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>

                        <Form.Item>
                            <Button
                                type="primary" htmlType="submit"
                                loading={loading}
                            >
                                Register
                            </Button>
                        </Form.Item>
                    </Form>
                    <div className='signin-link'>
                        <p>Already have an account? <a href="/signin">Sign in</a></p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default VolunteerRegister;