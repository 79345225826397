export const programs = [
    {
        id: '0001',
        title: 'Scratch Part 1',
        videoSinhala: 'https://www.youtube.com/embed/haUZjz8Ap9k?si=eb88Y81PNoc4rBYk',
        videoTamil: '',
        contentSinhala: '',
        contentTamil: '',
        sessionDate: '2024-11-09',
        month: 'November',
    },
    {
        id: '0002',
        title: 'Scratch Part 2',
        videoSinhala: 'https://www.youtube.com/embed/MSpqqe_bMeM?si=KgBbY41CtuUyy7ot',
        videoTamil: '',
        contentSinhala: '',
        contentTamil: '',
        sessionDate: '2024-12-07',
        month: 'December',
    },
    {
        id: '0003',
        title: 'Micro:bit 1',
        videoSinhala: '',
        videoTamil: '',
        contentSinhala: '',
        contentTamil: '',
        sessionDate: '2025-01-11',
        month: 'January',
    },
    {
        id: '0004',
        title: 'Micro:bit 2',
        videoSinhala: '',
        videoTamil: '',
        contentSinhala: '',
        contentTamil: '',
        sessionDate: '2025-01-11',
        month: 'February',
    },
    {
        id: '0005',
        title: 'Python',
        videoSinhala: '',
        videoTamil: '',
        contentSinhala: '',
        contentTamil: '',
        sessionDate: '2025-02-08',
        month: 'March',
    },
    {
        id: '0006',
        title: 'Python 2',
        videoSinhala: '',
        videoTamil: '',
        contentSinhala: '',
        contentTamil: '',
        sessionDate: '2025-03-08',
        month: 'April',
    },
    {
        id: '0007',
        title: 'Arduino 1',
        videoSinhala: '',
        videoTamil: '',
        contentSinhala: '',
        contentTamil: '',
        sessionDate: '2025-04-12',
        month: 'May',
    },
    {
        id: '0008',
        title: 'Arduino 2',
        videoSinhala: '',
        videoTamil: '',
        contentSinhala: '',
        contentTamil: '',
        sessionDate: '2025-05-10',
        month: 'June',
    },
    {
        id: '0009',
        title: 'Web Development',
        videoSinhala: '',
        videoTamil: '',
        contentSinhala: '',
        contentTamil: '',
        sessionDate: '2025-06-7',
        month: 'July',
    },
    {
        id: '0010',
        title: 'Web Development 2',
        videoSinhala: '',
        videoTamil: '',
        contentSinhala: '',
        contentTamil: '',
        sessionDate: '2025-07-12',
        month: 'August',
    },
    {
        id: '0011',
        title: 'Cyber Security',
        videoSinhala: '',
        videoTamil: '',
        contentSinhala: '',
        contentTamil: '',
        sessionDate: '2025-08-09',
        month: 'September',
    },
    {
        id: '0012',
        title: 'Artificial Intelligence (AI)',
        videoSinhala: '',
        videoTamil: '',
        contentSinhala: '',
        contentTamil: '',
        sessionDate: '2025-09-13',
        month: 'October',
    },
]